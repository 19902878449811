/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/jscroll@2.3.9/jquery.jscroll.min.js
 * - /npm/js-cookie@2.2.0/src/js.cookie.min.js
 * - /npm/phpjs@1.3.2/functions/datetime/strtotime.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.17.0/dist/additional-methods.min.js
 * - /npm/jsrender@0.9.90/jsrender.min.js
 * - /npm/svg4everybody@2.1.9/dist/svg4everybody.legacy.min.js
 * - /npm/sprintf-js@1.1.1/src/sprintf.min.js
 * - /npm/dompurify@2.2.6/dist/purify.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
